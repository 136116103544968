var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "card bg-light job-box rounded shadow border-0 overflow-hidden"
  }, [_vm.trust ? _c('div', {
    staticClass: "card-body"
  }, [_vm.alertMessage ? _c('div', {
    staticClass: "alert alert-success"
  }, [_vm._v(" " + _vm._s(_vm.alertMessage) + " ")]) : _vm._e(), ['1', '2'].includes(_vm.trust.hibah_type_id) && [-1, 0].includes(_vm.trust.status) ? _c('div', {
    staticClass: "p-3",
    staticStyle: {
      "position": "absolute",
      "top": "0%",
      "right": "0%"
    }
  }, [_c('router-link', {
    staticClass: "btn-sm btn-primary",
    attrs: {
      "to": {
        name: 'trust.part-c',
        query: {
          trust_id: _vm.trustId,
          amendment_id: _vm.amendmentId
        }
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("skip")) + " ")])], 1) : _vm._e(), _c('h5', {
    staticClass: "text-center"
  }, [_vm._v("Proses eKYC")]), !this.$isMobile() ? _c('div', {
    staticClass: "video-options"
  }, [_vm.videoDevices && _vm.videoDevices.length > 1 ? _c('select', {
    staticClass: "form-control",
    attrs: {
      "name": "",
      "id": ""
    },
    on: {
      "change": function ($event) {
        return _vm.selectCamera($event);
      }
    }
  }, _vm._l(_vm.videoDevices, function (device) {
    return _c('option', {
      key: device.deviceId,
      domProps: {
        "value": device.deviceId
      }
    }, [_vm._v(" " + _vm._s(device.label) + " ")]);
  }), 0) : _vm._e()]) : _vm._e(), this.$isMobile() ? _c('div', [_c('b-button', {
    staticClass: "btn-sm",
    on: {
      "click": _vm.mobileChangeCamera
    }
  }, [_vm._v(" " + _vm._s(_vm.mobileCamera))])], 1) : _vm._e(), _c('div', {
    attrs: {
      "id": _vm.selfie == null && !_vm.noCamera ? 'content' : 'content-ic'
    }
  }, [_c('video', {
    ref: "video",
    staticClass: "camera-stream",
    staticStyle: {
      "border-radius": "10px",
      "box-shadow": "0 3px 5px 0 rgba(47, 85, 212, 0.3)",
      "z-index": "0",
      "position": "relative"
    },
    attrs: {
      "playsinline": "",
      "autoplay": "",
      "width": "70%"
    }
  }), _c('canvas', {
    ref: "canvas",
    staticStyle: {
      "display": "none"
    }
  }), _vm._m(0)]), _vm.selfie == null && !_vm.noCamera ? _c('button', {
    staticClass: "btn btn-primary btn-xsm w-100",
    on: {
      "click": function ($event) {
        return _vm.capture('selfie');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("take-selfie")) + " ")]) : _vm._e(), _vm.mykad == null && _vm.selfie != null ? _c('button', {
    staticClass: "btn btn-primary btn-xsm w-100",
    on: {
      "click": function ($event) {
        return _vm.capture('mykad');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("take-mykad")) + " ")]) : _vm._e(), _vm.mykad && _vm.selfie ? _c('button', {
    staticClass: "btn btn-primary btn-xsm w-100",
    on: {
      "click": _vm.faceVerification
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("ekyc.verify")) + " ")]) : _vm._e(), _c('div', {
    staticClass: "text-right mt-4"
  }, [_c('small', [_vm._v(_vm._s(_vm.$t("failed-count")) + ": " + _vm._s(_vm.failedCount))])])]) : _vm._e()])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('img')]);
}]

export { render, staticRenderFns }