var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "alert alert-warning"
  }, [_vm._v(" " + _vm._s(_vm.$t("ekyc.failed")) + " ")]), _c('div', {
    staticClass: "card bg-light job-box rounded shadow border-0 overflow-hidden"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('h5', {
    staticClass: "text-center"
  }, [_vm._v(_vm._s(_vm.$t("ekyc.process")))]), _c('div', {
    staticClass: "row mt-4"
  }, [_c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', {
    attrs: {
      "for": "upload-photo-1"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("ekyc.selfie-with-mykad")) + " ")]), _c('div', {
    staticClass: "card rounded shadow bg-dark"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-center bd-highlight",
    staticStyle: {
      "height": "150px"
    },
    on: {
      "click": function ($event) {
        return _vm.openModal('Swafoto');
      }
    }
  }, [_vm.selfiePath ? _c('label', {
    staticStyle: {
      "border-radius": "5px",
      "margin-bottom": "0",
      "background-position": "center",
      "background-repeat": "no-repeat",
      "background-size": "cover",
      "height": "100%",
      "width": "100%"
    },
    style: 'background-image: url(' + _vm.selfiePath + ');',
    attrs: {
      "for": "upload-photo-2"
    }
  }) : _vm._e(), !_vm.selfiePath ? _c('label', {
    staticClass: "upload-photo-dark text-center",
    attrs: {
      "for": "upload-photo-2"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("ekyc.take-picture")))])]) : _vm._e()])]), _c('div', [_c('small', [_c('i', [_vm._v(_vm._s(_vm.$t("ekyc.put-mykad-with-face")))])])])]), _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', {
    attrs: {
      "for": "upload-photo-1"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("mykad-front")) + " ")]), _c('div', {
    staticClass: "card rounded shadow bg-dark"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-center bd-highlight",
    staticStyle: {
      "height": "150px"
    },
    on: {
      "click": function ($event) {
        return _vm.openModal('MyKad');
      }
    }
  }, [_vm.mykadFrontPath ? _c('label', {
    staticStyle: {
      "border-radius": "5px",
      "margin-bottom": "0",
      "background-position": "center",
      "background-repeat": "no-repeat",
      "background-size": "cover",
      "height": "100%",
      "width": "100%"
    },
    style: 'background-image: url(' + _vm.mykadFrontPath + ');',
    attrs: {
      "for": "upload-photo-2"
    }
  }) : _vm._e(), !_vm.mykadFrontPath ? _c('label', {
    staticClass: "upload-photo-dark text-center",
    attrs: {
      "for": "upload-photo-2"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("take-picture")))])]) : _vm._e()])])])])])]), _c('div', {
    staticClass: "text-right mt-3"
  }, [_c('button', {
    staticClass: "btn btn-primary",
    on: {
      "click": _vm.submitForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("save-next")) + " ")])]), _c('b-modal', {
    ref: "modalOcr",
    staticClass: "modal fade",
    attrs: {
      "id": "modalOcr",
      "title": "e-KYC",
      "ok-title": "Mulakan",
      "hide-footer": ""
    },
    on: {
      "close": _vm.closeEkyc
    }
  }, [!this.$isMobile() ? _c('div', {
    staticClass: "video-options"
  }, [_c('select', {
    staticClass: "form-control",
    attrs: {
      "name": "",
      "id": ""
    },
    on: {
      "change": function ($event) {
        return _vm.selectCamera($event);
      }
    }
  }, _vm._l(_vm.videoDevices, function (device) {
    return _c('option', {
      key: device.deviceId,
      domProps: {
        "value": device.deviceId
      }
    }, [_vm._v(" " + _vm._s(device.label) + " ")]);
  }), 0)]) : _vm._e(), this.$isMobile() ? _c('div', [_c('b-button', {
    staticClass: "btn-sm",
    on: {
      "click": _vm.mobileChangeCamera
    }
  }, [_vm._v(" " + _vm._s(_vm.mobileCamera) + " ")])], 1) : _vm._e(), _c('div', {
    attrs: {
      "id": _vm.selfie == null && !_vm.noCamera ? 'content' : 'content-ic'
    }
  }, [_c('video', {
    ref: "video",
    staticClass: "camera-stream",
    staticStyle: {
      "border-radius": "10px",
      "box-shadow": "0 3px 5px 0 rgba(47, 85, 212, 0.3)",
      "z-index": "0",
      "position": "relative"
    },
    attrs: {
      "playsinline": "",
      "autoplay": "",
      "width": "70%"
    }
  }), _c('canvas', {
    ref: "canvas",
    staticStyle: {
      "display": "none"
    }
  }), _c('p', [_c('img')])]), _c('button', {
    staticClass: "btn btn-primary btn-xsm w-100",
    on: {
      "click": function ($event) {
        return _vm.capture(_vm.captureType);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("take")) + " " + _vm._s(_vm.captureType) + " ")])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }