var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', [_c('div', {
    staticClass: "card bg-light job-box rounded shadow border-0 overflow-hidden"
  }, [_vm.trust ? _c('div', {
    staticClass: "card-body"
  }, [_c('h5', {
    staticClass: "text-center"
  }, [_vm._v(_vm._s(_vm.$t("ekyc.complete")))]), _c('div', {
    staticClass: "text-center"
  }, [_c('img', {
    attrs: {
      "src": "/images/secured.png",
      "alt": ""
    }
  }), _c('p', {
    staticClass: "mt-3",
    staticStyle: {
      "font-size": "10pt"
    }
  }, [_c('small', [_c('span', {
    staticClass: "d-block"
  }, [_vm._v(_vm._s(_vm.$t("verified-on")) + ": ")]), _c('strong', [_vm._v(_vm._s(_vm.trust.ekyc_verified_at))])])])]), _c('div', {
    staticClass: "row mt-4"
  }, [_c('div', {
    staticClass: "col-md-6 form-group text-center"
  }, [_c('label', {
    attrs: {
      "for": "upload-photo-1"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("ekyc.selfie-with-mykad")) + " ")]), _c('div', {
    staticClass: "card rounded shadow bg-dark"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-center bd-highlight",
    staticStyle: {
      "height": "150px"
    }
  }, [_vm.trust && _vm.trust.selfie && _vm.trust.selfie.public_path ? _c('label', {
    staticStyle: {
      "border-radius": "5px",
      "margin-bottom": "0",
      "background-position": "center",
      "background-repeat": "no-repeat",
      "background-size": "cover",
      "height": "100%",
      "width": "100%"
    },
    style: 'background-image: url(' + _vm.trust.selfie.public_path + ');',
    attrs: {
      "for": "upload-photo-2"
    }
  }) : _vm._e()])]), _c('div', {
    staticClass: "col-md-12 text-center mt-2"
  }, [_c('b-button', {
    staticClass: "btn-sm btn-primary m-1",
    on: {
      "click": function ($event) {
        return _vm.showIc('Swafoto');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("view")) + " ")])], 1)]), _c('div', {
    staticClass: "col-md-6 form-group text-center"
  }, [_c('label', {
    attrs: {
      "for": "upload-photo-1"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("mykad-front")) + " ")]), _c('div', {
    staticClass: "card rounded shadow bg-dark"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-center bd-highlight",
    staticStyle: {
      "height": "150px"
    }
  }, [_vm.trust && _vm.trust.mykad_front && _vm.trust.mykad_front.public_path ? _c('label', {
    staticStyle: {
      "border-radius": "5px",
      "margin-bottom": "0",
      "background-position": "center",
      "background-repeat": "no-repeat",
      "background-size": "cover",
      "height": "100%",
      "width": "100%"
    },
    style: 'background-image: url(' + _vm.trust.mykad_front.public_path + ');',
    attrs: {
      "for": "upload-photo-2"
    }
  }) : _vm._e()])]), _c('div', {
    staticClass: "col-md-12 text-center mt-2"
  }, [_c('b-button', {
    staticClass: "btn-sm btn-primary m-1",
    on: {
      "click": function ($event) {
        return _vm.showIc('front');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("view")) + " ")])], 1)])])]) : _vm._e()])]), _c('div', {
    staticClass: "text-right mt-3"
  }, [_c('button', {
    staticClass: "btn btn-primary",
    on: {
      "click": _vm.nextPart
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("next")) + " ")])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }